import axios from "axios";
import { get, set, startCase } from "lodash";
import { getOr, keyBy } from "lodash/fp";
import { IFileUploadRecord } from "../models/fileUpload.model";
import { FULFILLED, PENDING, REJECTED } from "./action-type.util";
import { ListOptions } from "./resource";

const resourceKey = "secureUploads";
const resourceUrl = "secureupload";
const resourceIdKey = "secureUploadID";
const resourceSymbol = Symbol(resourceKey);
const getActionData = getOr(null, "payload.data");
const itemsToMap = keyBy(resourceIdKey);
const itemsToProjection = items => items.map(i => i[resourceIdKey]);
const remapItem = item => ({ id: item[resourceIdKey], ...item });
const remapItems = items => items.map(remapItem);

export const ACTION_TYPES = {
  FETCH_LIST: "secureUpload/FETCH_LIST",
  DOWNLOAD_FILE: "secureUpload/DOWNLOAD_FILE",
  PUBLIC_DOWNLOAD_FILE: "publicDownload/DOWNLOAD_FILE",
  DELETE_FILE1: "secureUpload/DELETE_FILE",
  FETCH_UNREAD_FILES_COUNT: "secureUpload/FETCH_UNREAD_FILES_COUNT",
};

const initialState = {
  loading: false,
  map: {},
  error: null,
  errorMessage: null,
  projection: [],
  total: 0,
  listOptions: new ListOptions(),
  unreadCount: 0,
};

export interface ISecureUploadState {
  loading: boolean;
  map: { [id: number]: IFileUploadRecord };
  error: any;
  errorMessage: string;
  projection: Array<number>;
  total: number;
  listOptions: ListOptions;
  unreadCount: number;
}

/**
 * SecureUpload Reducer
 */
export const SecureUploadState = (state: ISecureUploadState = initialState, action): ISecureUploadState => {
  switch (action.type) {
    // Pending
    case PENDING(ACTION_TYPES.DELETE_FILE1): {
      return {
        ...state,
      };
    }

    case PENDING(ACTION_TYPES.FETCH_LIST): {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case PENDING(ACTION_TYPES.FETCH_UNREAD_FILES_COUNT): {
      return {
        ...state,
        loading: true,
      };
    }

    // Fulfilled
    case FULFILLED(ACTION_TYPES.FETCH_LIST): {
      const data = getActionData(action);
      const map = itemsToMap(data.items);
      const projection = itemsToProjection(data.items);
      const listOptions = action.meta.listOptions;

      return {
        ...state,
        map: {
          ...state.map,
          ...map,
        },
        projection,
        total: data.totalRows,
        listOptions,
        error: null,
        loading: false,
      };
    }

    case FULFILLED(ACTION_TYPES.DELETE_FILE1): {
      state.map[action.meta.secureDocumentID].deleted = true;
      return {
        ...state,
      };
    }

    case FULFILLED(ACTION_TYPES.FETCH_UNREAD_FILES_COUNT): {
      return {
        ...state,
        unreadCount: action.payload.data,
        loading: false,
      };
    }

    // Rejected
    case REJECTED(ACTION_TYPES.DELETE_FILE1): {
      return {
        ...state,
      };
    }
    case REJECTED(ACTION_TYPES.FETCH_LIST): {
      return {
        ...state,
        error: action.error,
        errorMessage: "Critical error: " + action.payload.response.data.error,
      };
    }

    default: {
      return state;
    }
  }
};

/**
 * Download the binary data for the specified document.
 * @param {string} documentID - Id of the specified document.
 */

export const downloadSecureDocument = (secureDocumentID: number) => {
  return {
    type: ACTION_TYPES.DOWNLOAD_FILE,
    payload: axios.get(`secureupload/${secureDocumentID}`, { responseType: "blob" }),
  };
};

/**
 * Download the binary data for the specified document.
 * @param {string} proposalID - Id of the proposal.
 * @param {string} documentID - Id of the specified document.
 */
export const downloadPublicDocument = (proposalID: string, documentID: string) => {
  return {
    type: ACTION_TYPES.PUBLIC_DOWNLOAD_FILE,
    payload: axios.get(`proposals/${proposalID}/doc/${documentID}`, { responseType: "blob" }),
  };
};

/**
 * Delete the binary data for the specified document, not file history
 * @param {string} documentID - Id of the specified document.
 */
export const deleteSecureDocument = (secureDocumentID: number) => {
  return {
    type: ACTION_TYPES.DELETE_FILE1,
    meta: { secureDocumentID },
    payload: axios.delete(`secureupload/${secureDocumentID}`),
  };
};

/**
 * Query a list of resources with the specified options.
 * @param {PageQuery} listOptions - query options.
 */
export const fetchUploadedFiles = (listOptions: ListOptions = new ListOptions()) => {
  const { page, rows, sort: sortOptions, query } = listOptions;

  const sort = get(sortOptions, "0.0");
  const sortOrder = startCase(get(sortOptions, "0.1"));

  return {
    type: ACTION_TYPES.FETCH_LIST,
    meta: { resourceSymbol, listOptions },
    payload: axios
      .get<IFileUploadRecord>(resourceUrl, {
        params: {
          ...query,
          page,
          sort,
          sortOrder,
          rows,
        },
      })
      .then(response => {
        const items = get(response, "data.items", null);
        if (items) set(response, "data.items", remapItems(items));
        return response;
      }),
  };
};

export const fetchUnreadFilesCount = () => {
  return {
    type: ACTION_TYPES.FETCH_UNREAD_FILES_COUNT,
    payload: axios.get("/secureupload/unread-files-count"),
  };
};
