import { flatten, get, uniq } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Button, Col, Container, Row } from "reactstrap";
import SingleSelectField from "../../../../../components/Form/SingleSelectField/SingleSelectField";

import * as S from "../../../../../constants/StringConstants";
import { IRootState } from "../../../../../store/reducers";

import { fetchAllProducts } from "src/store/reducers/productsV2";
import { addDefaultOptionFiltering } from "src/util/utils.defaultValues";
import "./Classifications.scss";

export interface IProposalDetailsClassificationProps extends StateProps, DispatchProps {
  name: string;
  index: number;
  value: any;
  productTypeID: number;
  carrierTypeID?: number;
  occupationClassTypeID?: number;
  designTypeID?: number;
  remove: (index: number) => any;
}

class Classification extends React.Component<IProposalDetailsClassificationProps> {
  async componentDidMount() {
    await this.props.fetchAllProducts();
  }

  render() {
    const { name, index, productTypeID, value, remove, allOptions } = this.props;

    const carrierTypeID = get(value, "carrierTypeID", "");
    const occupationClassTypeID = get(value, "occupationClassTypeID", "");

    // get all products list and then sequentially narrow the list down depending on selections
    let products = Object.values(this.props.products.map).filter(row => row.productTypeID === productTypeID);

    const carrierList = uniq(products.map(row => row.carrierTypeID));

    let occsList = [];
    let designList = [];

    if (carrierTypeID) {
      products = products.filter(row => row.carrierTypeID === carrierTypeID);
      occsList = uniq(flatten(products.map(product => product.occClassTypeIDs)));
    }

    if (occupationClassTypeID) {
      products = products.filter(row => row.occClassTypeIDs.includes(occupationClassTypeID));
      designList = uniq(products.map(product => product.designTypeID));
    }

    const carrierOptions = addDefaultOptionFiltering(allOptions.carrierTypeList, carrierList);
    const occClassOptions = addDefaultOptionFiltering(allOptions.occupationClassTypeList, occsList);
    const designOptions = addDefaultOptionFiltering(allOptions.designTypeList, designList);

    return (
      <Col key={`${name}`} sm="4">
        <Container className="classifications__condition-wrap">
          <Row>
            <Col>
              <SingleSelectField
                key="carrier-field"
                name={`${name}.carrierTypeID`}
                label={S.PBC_CARRIER_LABEL}
                options={carrierOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                disabled={carrierTypeID == ""}
                key={"occ-field"}
                name={`${name}.occupationClassTypeID`}
                label={S.PBC_OCC_LABEL}
                options={occClassOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                disabled={occupationClassTypeID == ""}
                key={"design-field"}
                name={`${name}.designTypeID`}
                label={S.PBC_PRODUCT_LABEL}
                options={designOptions}
              />
            </Col>
          </Row>
          {index > 0 && (
            <Row>
              <Col>
                <div>
                  <Button
                    color="secondary"
                    onClick={e => {
                      e.preventDefault();
                      remove(index);
                    }}>
                    {S.PBC_DELETE}
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </Col>
    );
  }
}

const mapStateToProps = function (state: IRootState) {
  return {
    products: state.productsV2,
    allOptions: state.productBuilder,
  };
};

const mapDispatchToProps = {
  fetchAllProducts,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Classification);
