import { IProductV2 } from "../models/product.model";
import ResourceFactory from "./resource";

const resourceKey = "productsV2";
const resourceUrl = "products/V2/products";
const resourceIdKey = "id";

const { reducer, fetchList, fetch, create, remove } = ResourceFactory<IProductV2>(
  resourceKey,
  resourceUrl,
  resourceIdKey,
);

export type ProductV2ResourcesState = ReturnType<typeof reducer>;

export const fetchProductsList = fetchList;
export const fetchAllProducts = fetchList.bind(null, { page: 1, rows: 1000, sort: null });
export const fetchProduct = fetch;
export const createProduct = create;
export const removeProduct = remove;

export default reducer;
