import { combineReducers } from "redux";

import affiliateBuilder, { AffiliateBuilderState } from "./affiliateBuilder";
import affiliates, { AffiliateResourcesState } from "./affiliates";
import affiliateImages, { AffiliateImageState } from "./affiliatesImages";
import analyzerTree, { AnalyzerTreeState } from "./analyzerTree";
import auth, { AuthState } from "./authentication";
import brokerBuilder, { BrokerBuilderState } from "./brokerBuilder";
import brokers, { BrokerResourcesState } from "./brokers";
import brokerSecureUpload, { BrokerSecureUploadState } from "./brokerSecureUpload";
import brokerImages, { BrokerImageState } from "./brokersImages";
import campaign, { CampaignResourcesState } from "./campaign";
import dncEmail, { DncEmailResourcesState } from "./campaignDncEmail";
import dncPhone, { DncPhoneResourcesState } from "./campaignDncPhone";
import companies, { CompanyResourcesState } from "./companies";
import companyBuilder, { CompanyBuilderState } from "./companyBuilder";
import correspondence, { CorrespondenceState } from "./correspondence";
import documentation, { DocumentationState } from "./documentation";
import healthCheck, { HealthCheckState } from "./healthCheck";
import preproposals, { PreproposalsResourcesState } from "./preproposals";
import productBuilder, { ProductBuilderState } from "./productBuilder";
import products, { ProductResourcesState } from "./products";
import productsV2, { ProductV2ResourcesState } from "./productsV2";
import proposalCensus, { ProposalCensusState } from "./proposalCensus";
import proposalDisclaimer, { IProposalDisclaimerState } from "./proposalDisclaimer";
import proposalIndividualCarier, { IProposalIndividualCarierState } from "./proposalIndividualCarier";
import proposalNotes, { ProposalNotesState } from "./proposalNotes";
import proposalOptions, { ProposalOptionsState } from "./proposalOptions";
import proposalRevisions, { ProposalRevisionsState } from "./proposalRevisions";
import proposals, { ProposalResourcesState } from "./proposals";
import questionAnswersStatus, { IQuestionAnswersStatusState } from "./question-answers-status";
import { ISecureUploadState, SecureUploadState } from "./secureUpload";
import templateBuilder, { TemplateBuilderState } from "./templateBuilder";
import templates, { TemplateResourcesState } from "./templates";
import users, { UsersState } from "./usersBuilder";

export interface IRootState {
  readonly auth: AuthState;
  readonly proposals: ProposalResourcesState;
  readonly proposalOptions: ProposalOptionsState;
  readonly productBuilder: ProductBuilderState;
  readonly brokers: BrokerResourcesState;
  readonly brokerImages: BrokerImageState;
  readonly companies: CompanyResourcesState;
  readonly affiliates: AffiliateResourcesState;
  readonly affiliateImages: AffiliateImageState;
  readonly products: ProductResourcesState;
  readonly productsV2: ProductV2ResourcesState;
  readonly companyBuilder: CompanyBuilderState;
  readonly affiliateBuilder: AffiliateBuilderState;
  readonly brokerBuilder: BrokerBuilderState;
  readonly analyzerTree: AnalyzerTreeState;
  readonly documentation: DocumentationState;
  readonly correspondence: CorrespondenceState;
  readonly proposalNotes: ProposalNotesState;
  readonly proposalIndividualCarier: IProposalIndividualCarierState;
  readonly proposalCensus: ProposalCensusState;
  readonly proposalRevisions: ProposalRevisionsState;
  readonly proposalDisclaimer: IProposalDisclaimerState;
  readonly questionAnswersStatus: IQuestionAnswersStatusState;
  readonly SecureUploadState: ISecureUploadState;
  readonly brokerSecureUpload: BrokerSecureUploadState;
  readonly healthCheck: HealthCheckState;
  readonly templates: TemplateResourcesState;
  readonly templateBuilder: TemplateBuilderState;
  readonly dncEmail: DncEmailResourcesState;
  readonly dncPhone: DncPhoneResourcesState;
  readonly campaign: CampaignResourcesState;
  readonly preproposals: PreproposalsResourcesState;
  readonly users: UsersState;
}

const rootReducer = combineReducers<IRootState>({
  auth,
  proposals,
  proposalOptions,
  productBuilder,
  brokers,
  brokerImages,
  companies,
  affiliates,
  affiliateImages,
  products,
  productsV2,
  companyBuilder,
  affiliateBuilder,
  brokerBuilder,
  analyzerTree,
  documentation,
  correspondence,
  proposalNotes,
  proposalCensus,
  proposalRevisions,
  proposalDisclaimer,
  questionAnswersStatus,
  SecureUploadState,
  brokerSecureUpload,
  proposalIndividualCarier,
  healthCheck,
  templates,
  templateBuilder,
  dncEmail,
  dncPhone,
  campaign,
  preproposals,
  users,
});

export default rootReducer;
