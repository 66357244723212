import axios from "axios";
import { get } from "lodash";

import { FULFILLED, PENDING, REJECTED } from "./action-type.util";

export const ACTION_TYPES = {
  FETCH_OPTIONS: "productBuilder/FETCH_OPTIONS",
  CREATE_PRODUCT: "productBuilder/CREATE_PRODUCT",
  TOGGLE_PROCESSSTART_SUCCESS_TOAST: "productBuilder/TOGGLE_PROCESSSTART_SUCCESS_TOAST",
  TOGGLE_PROCESSSTART_FAILED_TOAST: "productBuilder/TOGGLE_PROCESSSTART_FAILED_TOAST",
  TOGGLE_PROCESSSTART_INPROGRESS_TOAST: "productBuilder/TOGGLE_PROCESSSTART_INPROGRESS_TOAST",
};

const initialState = {
  loading: false,
  saving: false,
  createProcessStarted: false,
  createProcessFailed: false,
  productTypeList: [],
  carrierTypeList: [],
  occupationClassTypeList: [],
  designTypeList: [],
  renewabilityTypeList: [],
  bpTypeList: [],
  epTypeList: [],
  ownOccTypeList: [],
  residualTypeList: [],
  minimumResidualBenefitPayableTypeList: [],
  futureInsurabilityOptionsTypeList: [],
  airTypeList: [],
  colaTypeList: [],
  mentalNervousLimitationTypeList: [],
  catastrophicBenefitRiderTypeList: [],
  uniqueProvisionsTypeList: [],
};

export type ProductBuilderState = Readonly<typeof initialState>;

/*
 * Product Builder Reducer
 */
export default (state: ProductBuilderState = initialState, action): ProductBuilderState => {
  switch (action.type) {
    // Pending Actions
    case PENDING(ACTION_TYPES.FETCH_OPTIONS): {
      return {
        ...state,
        loading: true,
      };
    }
    case PENDING(ACTION_TYPES.CREATE_PRODUCT): {
      return {
        ...state,
        loading: true,
        saving: true,
      };
    }

    // Fulfilled Actions
    case FULFILLED(ACTION_TYPES.FETCH_OPTIONS): {
      const dropdownOptions = action.payload.data;

      return {
        ...state,
        ...dropdownOptions,
        loading: false,
      };
    }

    case FULFILLED(ACTION_TYPES.CREATE_PRODUCT): {
      return {
        ...state,
        loading: false,
        saving: false,
        createProcessFailed: false,
      };
    }

    // Rejected Actions
    case REJECTED(ACTION_TYPES.FETCH_OPTIONS): {
      return {
        ...state,
        loading: false,
        createProcessFailed: false,
      };
    }

    case REJECTED(ACTION_TYPES.CREATE_PRODUCT): {
      return {
        ...state,
        loading: false,
        saving: false,
        createProcessFailed: false,
      };
    }

    // Non network actions
    case ACTION_TYPES.TOGGLE_PROCESSSTART_INPROGRESS_TOAST: {
      const loading = get(action, "meta.open");
      return {
        ...state,
        loading,
      };
    }

    case ACTION_TYPES.TOGGLE_PROCESSSTART_FAILED_TOAST: {
      const createProcessFailed = get(action, "meta.open");
      return {
        ...state,
        createProcessFailed,
      };
    }

    default: {
      return state;
    }
  }
};

/**
 * Populate the Product Builder drop downs with options.
 */
export const fetchProductBuilderOptions = () => ({
  type: ACTION_TYPES.FETCH_OPTIONS,
  payload: axios.get("products/builder"),
});

/**
 * Create a new product with the specified product options.
 */
export const createProduct = (payload: any) => {
  return {
    type: ACTION_TYPES.CREATE_PRODUCT,
    payload: axios.post("products", payload),
  };
};

export const toggleProcessStartSuccessToast = (open: boolean) => {
  return {
    type: ACTION_TYPES.TOGGLE_PROCESSSTART_SUCCESS_TOAST,
    meta: { open },
  };
};

export const toggleProcessStartFailedToast = (open: boolean) => {
  return {
    type: ACTION_TYPES.TOGGLE_PROCESSSTART_FAILED_TOAST,
    meta: { open },
  };
};

export const toggleProcessInProgressToast = (open: boolean) => {
  return {
    type: ACTION_TYPES.TOGGLE_PROCESSSTART_INPROGRESS_TOAST,
    meta: { open },
  };
};
