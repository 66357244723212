import { get } from "lodash";
import React from "react";
import { Form as FinalForm } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { connect } from "react-redux";
import SingleSelectField from "../../../../../components/Form/SingleSelectField/SingleSelectField";
import ProposalStepper from "../../../../../components/ProposalStepper/ProposalStepper";
import Toast from "../../../../../components/Toast/Toast";
import { IProposal } from "../../../../../store/models/proposal.model";
import { IRootState } from "../../../../../store/reducers";
import { fetchDisclaimer } from "../../../../../store/reducers/proposalDisclaimer";
import {
  changeStatusTypeForProposal,
  changeUnderwriterForProposal,
  fetchOccupation,
  fetchProductTypeList,
  fetchProposal,
  fetchProposalClassifications,
  fetchProposalOptions,
  fetchStatusList,
  hideSaveToast,
  hideToast,
  resetBuilder,
  saveProposal,
  saveProposalClone,
  saveProposalNext,
  saveProposalPrevious,
  submitProposal,
} from "../../../../../store/reducers/proposalOptions";
import { fetchQuestionAnswers } from "../../../../../store/reducers/question-answers-status";
import { undefinedIfDefault } from "../../../../../util/utils.defaultValues";

import Analyzer from "./Analyzer";
import CaseDesign from "./CaseDesign";
import Classifications from "./Classifications";
import Client from "./Client";
import Overview from "./Overview";

import { RouteComponentProps } from "react-router";
import { ProposalStatus } from "src/store/models/proposalStatus.model";
import { fetchProductBuilderOptions } from "src/store/reducers/productBuilder";
import { fetchAllProducts } from "src/store/reducers/productsV2";
import * as S from "../../../../../constants/StringConstants";
import "./ProposalBuilder.scss";

// Symbols are global flags that determine which save action to take.
// These should be in Redux somehow.
export const saveSymbol = Symbol("SAVE FORM");
export const stepSymbol = Symbol("STEP FORM");
export const stepIndexSymbol = Symbol("STEP INDEX");

const numPages = 5;

/**
 * Proposal Builder Component Properties
 */
export interface IProposalBuilderProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
  proposalID: string;
  onBrokerChange: (notes: string) => void;
  cloneConter: number;
}

/**
 * Internal state of the Proposal Builder
 */
interface IProposalBuilderState {
  // current page number
  page: number;
  isSubmitting: boolean;
  cloneConter: number;
}

/**
 * Proposal Builder Component
 *
 * Contains 0-n pages and handles validation and submission across pages.
 */
export class ProposalBuilder extends React.Component<IProposalBuilderProps, IProposalBuilderState> {
  constructor(props) {
    super(props);

    const page = get(props, "history.location.state.pageIndex", 0);

    if (page !== 0) {
      this.props.history.replace(undefined, {});
    }

    this.state = {
      page: page,
      isSubmitting: false,
      cloneConter: 0,
    };
  }

  async componentDidMount() {
    await this.props.fetchProductBuilderOptions();
    await this.props.fetchAllProducts();

    const { user, proposalMap } = this.props;
    const { proposalID } = this.props;

    this.props.fetchStatusList();

    const id = parseInt(proposalID, 10);

    if (id) {
      this.props.fetchProposalClassifications(`${id}`);
      this.props.fetchQuestionAnswers(id);
      const proposal = proposalMap[proposalID];
      const occupationValue = get(proposal, "clients.0.occupationTypeID");

      const occupationTypeID = parseInt(occupationValue, 10);
      if (occupationTypeID) {
        this.props.fetchOccupation(occupationTypeID);
      }
    }
    // else {
    //   this.props.fetchProposalOptions(user.userID);
    // }
  }

  async componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.cloneConter !== this.state.cloneConter) {
      this.setState({ cloneConter: nextProps.cloneConter });
      await this.clone();
    }
  }

  /**
   * Submit the current values and navigate to the next page.
   */
  next = async values => {
    const { page } = this.state;
    await this.submit(Math.min(page + 1, numPages - 1), values, this.props.saveProposalNext);
  };

  /**
   * Submit the current values and navigate to the previous page.
   */
  previous = async values => {
    const { page } = this.state;
    await this.submit(Math.max(page - 1, 0), values, this.props.saveProposalPrevious);
  };

  /**
   * Submit the current values from he save button in upper right
   */
  generatePDF = async values => {
    const { page } = this.state;
    await this.submit(page, values, this.props.submitProposal);
  };

  step = async (values, pageIndex) => {
    await this.submit(pageIndex, values, this.props.saveProposal);
  };

  clone = async () => {
    const { proposalID, proposalMap } = this.props;
    const proposal = proposalMap[proposalID];

    const proposalToClone = this.formToClonedProposal(proposal);
    const result = await this.props.saveProposalClone(proposalToClone);
    const newId = get(result, "value.data.proposalID");

    window.open(`/proposals/${newId}/details`);
  };

  submit = async (nextPage: number, values: any, save: (values: any) => void) => {
    const proposal = this.formToProposal(values);

    const result = await save(proposal);

    const proposalID = get(result, "value.data.proposalID");

    const id = parseInt(proposalID, 10);
    this.props.fetchProposalClassifications(`${id}`);

    const currProposalID = parseInt(this.props.proposalID, 10);
    if (proposalID) {
      this.props.fetchQuestionAnswers(proposalID);
    }

    if (proposalID !== currProposalID) {
      // we have a new proposal, get the disclaimer. this is so sloppy I'm sorry.
      this.props.fetchDisclaimer(proposalID);
      this.setState(state => ({
        page: nextPage,
      }));
      this.props.history.push(`/proposals/${proposalID}/details`, { pageIndex: nextPage });
    } else {
      if (this !== undefined) {
        this.setState(state => ({
          page: nextPage,
        }));
      }
    }
  };

  formToProposal = formValues => {
    const { proposalID } = this.props;

    const {
      productTypeID,
      specialistID,
      underwriterID,
      broker,
      isMultiLife,
      clients,
      hearAboutUsTypeID,
      originationTypeID,
      classifications,
      bpTypeID,
      epTypeID,
      payorTypeID,
      benefitAmount,
      isMaxBenefit,
      riders,
      statusTypeID,
      clientNote,
      isFiveCarrier,
    } = formValues;

    if (classifications) {
      // Filter out any incomplete classification properties
      const completeClassifications = classifications.map(c => {
        return {
          ...c,
          carrierTypeID: undefinedIfDefault(c.carrierTypeID),
          occupationClassTypeID: undefinedIfDefault(c.occupationClassTypeID),
          designTypeID: undefinedIfDefault(c.designTypeID),
          analyzer: {
            ...c.analyzer,
            renewabilityTypeID: undefinedIfDefault(c.analyzer.renewabilityTypeID),
            bpTypeID: undefinedIfDefault(c.analyzer.bpTypeID),
            epTypeID: undefinedIfDefault(c.analyzer.epTypeID),
            ownOccTypeID: undefinedIfDefault(c.analyzer.ownOccTypeID),
            residualTypeID: undefinedIfDefault(c.analyzer.residualTypeID),
            minimumResidualBenefitPayableTypeID: undefinedIfDefault(c.analyzer.minimumResidualBenefitPayableTypeID),
            futureInsurabilityOptionsTypeID: undefinedIfDefault(c.analyzer.futureInsurabilityOptionsTypeID),
            airTypeID: undefinedIfDefault(c.analyzer.airTypeID),
            colaTypeID: undefinedIfDefault(c.analyzer.colaTypeID),
            mentalNervousLimitationTypeID: undefinedIfDefault(c.analyzer.mentalNervousLimitationTypeID),
            catastrophicBenefitRiderTypeID: undefinedIfDefault(c.analyzer.catastrophicBenefitRiderTypeID),
            uniqueProvisionsTypeID: undefinedIfDefault(c.analyzer.uniqueProvisionsTypeID),
          },
        };
      });
    }

    const completeClients = clients.map(c => {
      return {
        ...c,
        prefixTypeID: undefinedIfDefault(c.prefixTypeID),
        genderTypeID: undefinedIfDefault(c.genderTypeID),
        tobaccoTypeID: undefinedIfDefault(c.tobaccoTypeID),
        tobaccoFrequencyID: undefinedIfDefault(c.tobaccoFrequencyID),
        isReplaceExistingCoverage: c.isReplaceExistingCoverage ? true : false,
      };
    });

    const proposal = {
      proposalID: proposalID === "new" ? undefined : proposalID,
      productTypeID: undefinedIfDefault(productTypeID),
      specialistID: undefinedIfDefault(specialistID),
      underwriterID: undefinedIfDefault(underwriterID),
      brokerID: broker.brokerID,
      isMultiLife,
      clients: completeClients,
      hearAboutUsTypeID,
      originationTypeID,
      classifications,
      bpTypeID,
      epTypeID,
      payorTypeID,
      benefitAmount,
      isMaxBenefit,
      riders,
      statusTypeID,
      clientNote,
      isFiveCarrier,

      step: this.state.page,
    };

    return proposal;
  };

  formToClonedProposal = formValues => {
    const {
      productTypeID,
      specialistID,
      underwriterID,
      broker,
      isMultiLife,
      clients,
      hearAboutUsTypeID,
      originationTypeID,
      classifications,
      bpTypeID,
      epTypeID,
      payorTypeID,
      benefitAmount,
      isMaxBenefit,
      riders,
      statusTypeID,
      clientNote,
      isFiveCarrier,
    } = formValues;

    const completeClients = clients.map(c => {
      return {
        ...c,
        clientID: 0,
        prefixTypeID: undefinedIfDefault(c.prefixTypeID),
        genderTypeID: undefinedIfDefault(c.genderTypeID),
        tobaccoTypeID: undefinedIfDefault(c.tobaccoTypeID),
        tobaccoFrequencyID: undefinedIfDefault(c.tobaccoFrequencyID),
        isReplaceExistingCoverage: c.isReplaceExistingCoverage ? true : false,
      };
    });

    const proposal = {
      // no proposalID
      IsFromCloneProposal: true,
      productTypeID: undefinedIfDefault(productTypeID),
      specialistID: undefinedIfDefault(specialistID),
      underwriterID: undefinedIfDefault(underwriterID),
      brokerID: broker.brokerID,
      isMultiLife,
      clients: completeClients,
      hearAboutUsTypeID,
      originationTypeID,
      classifications: [],
      bpTypeID,
      epTypeID,
      payorTypeID,
      benefitAmount,
      isMaxBenefit,
      riders,
      statusTypeID: ProposalStatus.Unassigned,
      clientNote,
      isFiveCarrier,
      step: 0,
    };

    return proposal;
  };

  /**
   * Render the component for the active step in the ProposalBuilder.
   * @param formProps
   */
  renderActivePage(proposal: IProposal) {
    const { proposalID } = this.props;
    const { onBrokerChange } = this.props;

    switch (this.state.page) {
      case 0:
        return (
          <Overview
            next={this.next}
            proposalID={proposalID}
            proposal={proposal}
            onBrokerChange={onBrokerChange}
            generatePDF={this.generatePDF}
            step={this.step}
          />
        );
      case 1:
        return (
          <Client
            next={this.next}
            previous={this.previous}
            proposal={proposal}
            generatePDF={this.generatePDF}
            step={this.step}
          />
        );
      case 2:
        return (
          <Classifications
            next={this.next}
            previous={this.previous}
            proposal={proposal}
            generatePDF={this.generatePDF}
            step={this.step}
          />
        );
      case 3:
        return (
          <CaseDesign
            next={this.next}
            previous={this.previous}
            proposal={proposal}
            productTypeID={proposal.productTypeID}
            generatePDF={this.generatePDF}
            step={this.step}
          />
        );
      case 4:
        return (
          <Analyzer
            next={this.next}
            previous={this.previous}
            proposal={proposal}
            generatePDF={this.generatePDF}
            step={this.step}
          />
        );
      default:
        // if by some magic we have a invalid case, reset the user
        this.setState(state => ({ page: 0 }));
        return null;
    }
  }

  onCopyButtonClick(text) {
    navigator.clipboard.writeText(text);
  }

  render() {
    const {
      statusList,
      isSubmitToastVisible,
      isSaveToastVisible,
      proposalMap,
      isClassificationLoaded,
      overviewOptions: { selectUnderwriterList },
    } = this.props;

    const { page } = this.state;
    const { proposalID } = this.props;

    const proposal = proposalMap[proposalID];
    const statusTypeID = get(proposal, "statusTypeID");
    const underwriterID = get(proposal, "underwriterID");

    // HACK: the page number should be moved into redux or something smarter.
    // The global "currPage" is used for submitting the form via the Save button
    // on the ProposalContainer. This is bad. But so is murder.
    window["currPage"] = page;

    return (
      <div>
        <div>
          <Toast
            message={S.PB_SUBMIT_SUCCESS_ANALYZER_TOAST}
            onClose={() => {
              this.props.hideToast();
            }}
            open={isSubmitToastVisible}
          />
          <Toast
            message={S.PB_SAVE_SUCCESS_TOAST}
            onClose={() => {
              this.props.hideSaveToast();
            }}
            open={isSaveToastVisible}
          />
          <Toast
            message={S.PB_PROPOSAL_SUBMITTED}
            onClose={() => {
              this.props.hideSaveToast();
            }}
            open={this.state.isSubmitting}
          />
          <div className="proposal-details__builder">
            <div className="proposal-details__head-id">
              {/*More hackity hack hacks.. this should be better. Is it even a hack with javascript or is it just the way of life?*/}
              <FinalForm onSubmit={() => {}} initialValues={{ statusTypeID, underwriterID }}>
                {formProps => {
                  return (
                    <div className="caseStatus">
                      <div
                        className="item pointer"
                        title={S.PB_COPY_TOOLTIP}
                        onClick={() => this.onCopyButtonClick(proposalID)}>
                        <label>{S.PB_PROPOSAL_ID}:</label>
                        <span className="value">{proposalID}</span>
                        <span className="material-icons">content_copy</span>
                      </div>
                      <div className="item">
                        <label>{S.PB_UNDERWRITER_LABEL}:</label>
                        <SingleSelectField
                          key={"proposal-underwriter-field"}
                          name={"underwriterID"}
                          options={selectUnderwriterList}
                        />
                      </div>
                      <div className="item">
                        <label>{S.PB_STATUS_LABEL}:</label>
                        <SingleSelectField key={"proposal-status-field"} name={"statusTypeID"} options={statusList} />
                      </div>
                      <OnChange name="statusTypeID">
                        {value => {
                          this.props.changeStatusTypeForProposal(proposalID, value);
                        }}
                      </OnChange>
                      <OnChange name="underwriterID">
                        {value => {
                          this.props.changeUnderwriterForProposal(proposalID, value);
                        }}
                      </OnChange>
                    </div>
                  );
                }}
              </FinalForm>
            </div>
            <ProposalStepper
              activeStep={page}
              isClassificationLoaded={isClassificationLoaded}
              onClick={async (e, stepIndex) => {
                const form = document.getElementById(`proposal-builder-form-${page}`);
                if (form) {
                  window[stepSymbol] = true;
                  window[stepIndexSymbol] = stepIndex;
                  // FIXME: this will submit the form but doesn't handle
                  // the form submission properly. It should be refactored to
                  // handle the error and success cases properly.
                  form.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
                }
              }}
              steps={[
                {
                  label: S.PDS_OVERVIEW,
                  to: `/proposals/${proposalID}/details/overview`,
                },
                {
                  label: S.PDS_CLIENT,
                  to: `/proposals/${proposalID}/details/client`,
                },
                {
                  label: S.PDS_CLASSIFICATION,
                  to: `/proposals/${proposalID}/details/classification`,
                },
                {
                  label: S.PDS_CASE_DESIGN,
                  to: `/proposals/${proposalID}/details/case-design`,
                },
                {
                  label: S.PDS_ANALYZER,
                  to: `/proposals/${proposalID}/details/analyzer`,
                },
              ]}
            />
            {this.renderActivePage(proposal)}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  let bpTypeFullList = state.proposalOptions.classificationOptions
    ? state.proposalOptions.classificationOptions.bpTypeFullList
    : [];
  let designTypeFullList = state.proposalOptions.classificationOptions
    ? state.proposalOptions.classificationOptions.designTypeFullList
    : [];
  return {
    user: state.auth.user,
    isSubmitToastVisible: state.proposalOptions.isSubmitToastVisible,
    isClassificationLoaded: state.proposalOptions.isClassificationLoaded,
    statusList: state.proposalOptions.statusList,
    bpTypeFullList: bpTypeFullList,
    designTypeFullList: designTypeFullList,
    isSaveToastVisible: state.proposalOptions.isSaveToastVisible,
    proposalMap: state.proposalOptions.proposalMap,
    overviewOptions: state.proposalOptions.overviewOptions,
  };
};

const mapDispatchToProps = {
  fetchProposalOptions,
  fetchStatusList,
  fetchProductTypeList,
  fetchProposal,
  fetchProposalClassifications,
  saveProposal,
  saveProposalClone,
  submitProposal,
  resetBuilder,
  hideToast,
  hideSaveToast,
  fetchOccupation,
  fetchDisclaimer,
  saveProposalNext,
  saveProposalPrevious,
  changeStatusTypeForProposal,
  changeUnderwriterForProposal,
  fetchQuestionAnswers,
  fetchProductBuilderOptions,
  fetchAllProducts,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProposalBuilder);
