/**
 *  Companies.tsx
 *  Disability Insurance Services
 *
 *  Created by Jeremy Moyers on Wed Apr 24 13:02:42 2019
 *  Copyright Seamgen, LLC. All rights reserved.
 */
import { get, memoize } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Button } from "reactstrap";

import * as S from "../../constants/StringConstants";
import { IProductV2 } from "../../store/models/product.model";
import { IRootState } from "../../store/reducers";
import { fetchProductsList, removeProduct } from "../../store/reducers/productsV2";

import { fetchProductBuilderOptions } from "src/store/reducers/productBuilder";
import SearchFieldTool from "../../components/Table/SearchField";
import DisTable from "../../components/Table/Table";

interface IProductsProps extends StateProps, DispatchProps, RouteComponentProps {}

const getMap = memoize(list => list.reduce((map, { key, value }) => ({ ...map, [value]: key }), {}));

export class Products extends React.Component<IProductsProps> {
  async componentDidMount() {
    await this.props.fetchProductBuilderOptions();
  }
  deleteProduct = async function (e, product: IProductV2) {
    e.stopPropagation();
    if (!window.confirm(S.PROD_CONFIRM_DELETE)) {
      return;
    }
    try {
      await this.props.removeProduct(product.id);
      await this.props.fetchProductsList();
    } catch (err) {}
  };

  render() {
    const {
      fetchProductsList,
      productBuilder: pb,
      products: { map, projection, total, listOptions },
    } = this.props;

    const columns = [
      {
        key: "productType",
        title: S.PROD_COL_PRODUCT_TYPE,
        render: p => get(getMap(pb.productTypeList), p.productTypeID, ""),
        style: { minWidth: "12rem" },
      },
      {
        key: "carrierType",
        title: S.PROD_COL_CARRIER_TYPE,
        render: p => get(getMap(pb.carrierTypeList), p.carrierTypeID, ""),
        style: { minWidth: "12rem", paddingLeft: "1rem" },
      },
      {
        key: "designType",
        title: S.PROD_COL_DESIGN_TYPE,
        render: p => get(getMap(pb.designTypeList), p.designTypeID, ""),
        style: { minWidth: "7rem" },
      },
      {
        key: "deleteProduct",
        title: "",
        render: (item: any) => {
          return (
            <Button color="secondary" onClick={e => this.deleteProduct(e, item)}>
              {S.PROD_DELETE_BUTTON}
            </Button>
          );
        },
        style: { minWidth: "7rem" },
      },
    ];

    return (
      <div className="table-view__content">
        <h1 className="heading1 grey--light">{S.PROD_PAGE_TITLE}</h1>
        <DisTable<IProductV2>
          columns={columns}
          initialState={{ rows: 25, map, projection, total, ...listOptions }}
          onRowClick={(data: IProductV2) => {
            this.props.history.push(`/products/${data.id}`);
          }}
          onUpdate={async ({ page, rows, sort, query }) => {
            const result: any = await fetchProductsList({ page, rows, sort, query });
            return {
              total: get(result, "value.data.totalRows") as number,
              data: get(result, "value.data.items") as IProductV2[],
            };
          }}>
          <SearchFieldTool disabled />
        </DisTable>
      </div>
    );
  }
}

const mapStateToProps = ({ productsV2: products, productBuilder }: IRootState) => ({ products, productBuilder });
const mapDispatchToProps = { fetchProductsList, removeProduct, fetchProductBuilderOptions };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Products));
