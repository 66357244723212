import classNames from "classnames";
import React from "react";
import { Button, Input, InputGroup } from "reactstrap";
import FinalTableSpy from "../FinalTable/FinalTableSpy";
import "./SearchField.scss";

interface SearchFieldProps {
  disabled?: boolean;
}

export default class SearchField extends React.Component<SearchFieldProps> {
  render() {
    const { disabled } = this.props;
    return (
      <FinalTableSpy>
        {props => (
          <div className={classNames("table-search", { disabled })}>
            <form
              onSubmit={event => {
                event.preventDefault();
                const searchText = event.target[0].value;
                props.update({ page: 1, query: { searchText } });
              }}>
              <InputGroup>
                <Input type="search" disabled={disabled} />
                <Button color="secondary" className="search-btn" />
              </InputGroup>
            </form>
          </div>
        )}
      </FinalTableSpy>
    );
  }
}
