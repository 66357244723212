import { Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import TextField from "src/components/Form/TextField/TextField";
import * as S from "../../../constants/StringConstants";
import "./ManageProductItemsModal.scss";

interface IManageProductItemsModalProps {
  open: boolean;
  onClose: () => void;
  productName: string;
  items: Array<{ key: string; value: number }>;
  onSave: (updatedItems: Array<{ key: string; value?: number }>) => void;
}

const ManageProductItemsModal: React.FC<IManageProductItemsModalProps> = ({
  open,
  onClose,
  productName,
  items,
  onSave,
}) => {
  const [currentItems, setCurrentItems] = useState<Array<{ key: string; value: number }>>(items);
  const [newItems, setNewItems] = useState<Array<{ key: string; value: number }>>([]);
  const [newItemsCount, setNewItemsCount] = useState(0);

  useEffect(() => {
    if (open) {
      setCurrentItems(items);
      setNewItems([]);
      setNewItemsCount(0);
    }
  }, [open, items]);

  const handleItemChange = (index: number, newKey: string) => {
    const updatedItems = [...currentItems];
    updatedItems[index].key = newKey;
    setCurrentItems(updatedItems);
  };

  const handleAddNewItem = form => {
    const newItemValues = Array.from({ length: newItemsCount }, (_, index) => {
      return form.getFieldState(`new-item-${index}`)?.value || "";
    });

    const updatedNewItems = newItemValues.map(key => ({ key, value: null }));

    setNewItems([...updatedNewItems, { key: "", value: null }]);
    setNewItemsCount(newItemsCount + 1);
  };

  const handleDeleteItem = (index: number, isNew: boolean, form) => {
    const newItemValues = Array.from({ length: newItems.length }, (_, idx) => {
      return form.getFieldState(`new-item-${idx}`)?.value || "";
    });

    const updatedNewItems = newItemValues
      .map((key, idx) => ({
        key,
        value: null,
      }))
      .filter(item => item.key.trim());

    setNewItems(updatedNewItems);

    if (isNew) {
      const updatedNewItemsAfterDeletion = [...updatedNewItems];
      updatedNewItemsAfterDeletion.splice(index, 1);
      setNewItems(updatedNewItemsAfterDeletion);
      setNewItemsCount(newItemsCount - 1);
    } else {
      const updatedItems = [...currentItems];
      updatedItems.splice(index, 1);
      setCurrentItems(updatedItems);
    }
  };

  const handleSave = (formData: any) => {
    const updatedItems = currentItems.map((item, index) => ({
      key: formData[`item-${index}`] || item.key,
      value: item.value,
    }));

    const updatedNewItems = newItems
      .map((item, index) => ({
        key: formData[`new-item-${index}`] || item.key,
        value: item.value,
      }))
      .filter(item => item.key.trim());

    onSave([...updatedItems, ...updatedNewItems]);

    setNewItems([]);
    setNewItemsCount(0);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ fontSize: "1.5rem" }}>Manage Items for {productName}</DialogTitle>

      <Form
        onSubmit={handleSave}
        initialValues={{
          ...currentItems.reduce((acc, item, index) => ({ ...acc, [`item-${index}`]: item.key }), {}),
          ...newItems.reduce((acc, item, index) => ({ ...acc, [`new-item-${index}`]: item.key }), {}),
        }}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent dividers style={{ maxHeight: "60vh", overflowY: "auto" }}>
              {currentItems.map((item, index) => (
                <div key={`item-${index}`} className="manage-product-items-modal__item-row">
                  <div className="manage-product-items-modal__input">
                    <Field
                      name={`item-${index}`}
                      render={({ input }) => (
                        <TextField {...input} onChange={e => handleItemChange(index, e.target.value)} />
                      )}
                    />
                  </div>
                  <IconButton
                    onClick={() => handleDeleteItem(index, false, form)}
                    aria-label="delete"
                    className="manage-product-items-modal__delete-button">
                    <Icon>delete</Icon>
                  </IconButton>
                </div>
              ))}

              {Array.from({ length: newItemsCount }, (_, index) => (
                <div key={`new-item-${index}`} className="manage-product-items-modal__item-row">
                  <div className="manage-product-items-modal__input">
                    <Field
                      name={`new-item-${index}`}
                      render={({ input }) => <TextField {...input} placeholder="New Item" />}
                    />
                  </div>
                  <IconButton
                    onClick={() => handleDeleteItem(index, true, form)}
                    aria-label="delete"
                    className="manage-product-items-modal__delete-button">
                    <Icon>delete</Icon>
                  </IconButton>
                </div>
              ))}

              <button
                type="button"
                onClick={() => handleAddNewItem(form)}
                className="button__white button__white--small manage-product-items-modal__add-button">
                Add Item
              </button>
            </DialogContent>

            <DialogActions>
              <button className="form-modal__cancel-button" type="button" onClick={onClose}>
                {S.M_CANCEL_ACTION}
              </button>
              <button className="form-modal__save-button" type="submit">
                {S.M_SAVE_ACTION}
              </button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

export default ManageProductItemsModal;
