import { Button, Icon } from "@mui/material";
import classNames from "classnames";
import moment from "moment";
import Delta from "quill-delta";
import React, { useRef, useState } from "react";
import { Field, useField, useForm } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { OnChange } from "react-final-form-listeners";
import ReactQuill from "react-quill";
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";
import AsyncTypeaheadField from "src/components/Form/TypeaheadField/AsyncTypeaheadField";
import { ITypeaheadOption } from "src/components/Form/TypeaheadField/TypeaheadField";
import InputReactQuill from "src/components/InputEditor/InputReactQuill";
import { isValidEmail } from "src/util/utils.validators";
import * as S from "../../constants/StringConstants";
import { ColumnType, CTA_LINK_OPTIONS } from "../CampaignBuilder/Constants/columns";
import { addNewlines, stripTagsKeepNewlines } from "../CampaignBuilder/utils";
import "./MessageSequencer.scss";
import { VariableSelectDropdown } from "./VariableSelectDropdown";

// todo: improve onchange events

interface MessageSequencerProps {
  name: string;
  columns: ColumnType[];
  // could not make this properly typed, try again later
  // { steps: IStepList; }
  values?: {
    [key: string]: any;
  };
  enableCalendar?: boolean;
  firstDate?: Date;
  onAddClicked?: () => void;
  onDeleteStep?: (id: string) => void;
}

export const typeList = [
  {
    key: "Email",
    value: "email",
  },
  {
    key: "Text message",
    value: "sms",
  },
];

const makeOption = (value, label): ITypeaheadOption => ({ label, value });

const getSelectedEmails = (list: ITypeaheadOption[], values: [string]): ITypeaheadOption[] => {
  return (values || []).map(email => {
    const existingItem = list.find(listItem => listItem.value === email);
    return existingItem || makeOption(email, email);
  });
};

// TODO: this function is mostly duplicated from getSelectedEmails make a more generic function?
const getSelectedPhones = (list: ITypeaheadOption[], values: [string]): ITypeaheadOption[] => {
  return (values || []).map(phone => {
    const existingItem = list.find(listItem => listItem.value === phone);
    return existingItem || makeOption(phone, phone);
  });
};

export const MessageSequencer = ({
  name,
  columns,
  onAddClicked,
  enableCalendar = false,
  firstDate = new Date(),
  onDeleteStep,
}: MessageSequencerProps) => {
  const [mailSubjectEditorActive, setMailSubjectEditorActive] = useState(false);
  const [mailBodyEditorActive, setMailBodyEditorActive] = useState(false);
  const [smsEditorActive, setSmsEditorActive] = useState(false);

  const field = useField(name);
  const form = useForm();
  const isEmpty = field.input.value.length === 0;

  const editorRef = useRef<{
    [key: string]: ReactQuill | null;
  }>({});

  const variableOptions = columns.map(col => ({
    label: col.label,
    value: `{{ ${col.key} }}`,
  }));

  const toEmailList: ITypeaheadOption[] = columns
    .filter(col => col.isEmail)
    .map(({ label, key }) => ({ label, value: key }));

  const fromEmailList: ITypeaheadOption[] = columns
    .filter(col => col.isEmail)
    .map(({ label, key }) => ({ label, value: key }));

  const toPhoneList: ITypeaheadOption[] = columns
    .filter(col => col.isPhone)
    .map(({ label, key }) => ({ label, value: key }));

  const disOption = makeOption("increaseoptions@diservices.com", "DIS - increaseoptions@diservices.com");
  toEmailList.push(disOption);
  fromEmailList.push(disOption);

  const mockFetch = (emailList: ITypeaheadOption[]) => {
    return async (q?: string) => {
      const ret = [...emailList];
      if (isValidEmail(q)) {
        ret.push({ label: q, value: q });
      }
      return Promise.resolve(ret);
    };
  };

  const mockFetchForPhone = (phoneList: ITypeaheadOption[]) => {
    return async (_q?: string) => {
      return Promise.resolve(phoneList);
    };
  };

  /**
   *
   * @param editorRefKey @string
   * @param value @string
   * @returns @void
   * @description Inserts the value into the editor at the current cursor position.
   * Helpful for inserting variables such as {{ clientEmail }} into the editor.
   */
  const insertValueIntoEditor = (editorRefKey: string, value: string, isHTML = false) => {
    const editor = editorRef.current?.[editorRefKey]?.getEditor();
    if (!editor) return;

    const cursorPosition = editor.getSelection().index;

    if (!isHTML) {
      editor.insertText(cursorPosition, value);
    } else {
      const clipboardDelta = editor.clipboard.convert(value);
      const delta = new Delta().retain(cursorPosition).concat(clipboardDelta);
      editor.updateContents(delta, "user");
    }
    form.change(editorRefKey, editor.root.innerHTML);
  };

  const deleteStep = (fields, index: number) => {
    if (window.confirm(S.TPL_CONFIRM_DELETE_STEP)) {
      const id = fields.value[index].stepId;
      fields.remove(index);
      onDeleteStep(id);
    }
  };

  const handleMoveStep = (fields, index, e) => {
    // fields.move does not work and we also need to update the intervalDays anyway
    const newIndex = parseInt(e.target.value, 10);
    const allIntervalDays = fields.value.map(f => f.intervalDays);

    const movedItem = fields.remove(index);
    movedItem.intervalDays = allIntervalDays[newIndex];
    fields.insert(newIndex, { ...movedItem });

    // move interval days over
    fields.value.forEach((_f, i) => {
      form.change(`templateSteps[${i}].intervalDays`, allIntervalDays[i]);
    });
  };

  const isStepEditable = (field, index: number) => {
    let dueDateTime = null;
    let status = null;
    try {
      dueDateTime = field.input.value[index].messageQueues[0].dueDateTime;
      status = field.input.value[index].messageQueues[0].status;
    } catch (e) {}
    if (dueDateTime !== null) {
      if (moment(dueDateTime).isSameOrBefore(moment())) {
        return false;
      }
    }
    return true;
  };

  return (
    <>
      <FieldArray name={name}>
        {({ fields }) =>
          fields.map((fieldName, index) => {
            const offset = fields.value.slice(0, index).reduce((p, c) => {
              return p + parseInt(c.intervalDays, 10);
            }, 0);
            const calcDate = d => moment(firstDate).add(offset, "days").add(d, "days").format("YYYY-MM-DD");
            return (
              <div
                className={classNames("io-box step", { "non-editable": !isStepEditable(field, index) })}
                key={fields.value[index].stepId || fields.value[index].newStepId || fields.value[index].detailId}>
                {!isStepEditable(field, index) && <div className="non-editable-overlay">{S.TPL_NOT_EDITABLE}</div>}
                <Row>
                  <Col>
                    <h1 className="heading3">
                      {S.TPL_STEP_N}
                      {index + 1}
                    </h1>
                  </Col>
                </Row>
                <Row className="options" xs={2}>
                  <Col xs={4}>
                    {S.TPL_STEP_TYPE}:
                    <Field name={`${fieldName}.kind`} component="select">
                      {typeList.map(item => (
                        <option key={item.value} value={item.value}>
                          {item.key}
                        </option>
                      ))}
                    </Field>
                  </Col>
                  <Col align="right" xs={8}>
                    <div className="step-actions">
                      {index > 0 && (
                        <Field name={`${fieldName}.intervalDays`} component="input">
                          {({ input, meta }) => (
                            <>
                              {!enableCalendar ? (
                                <>
                                  {S.TPL_INTERVAL_DAYS}:
                                  <input
                                    {...input}
                                    type="text"
                                    className={classNames("intervalDays", { hasError: meta.error && meta.touched })}
                                  />
                                </>
                              ) : (
                                <>
                                  {S.TPL_WAITING_DAYS(input.value)}
                                  <input
                                    type="date"
                                    value={calcDate(input.value)}
                                    onChange={e => {
                                      const prev = calcDate(input.value);
                                      const diff = moment(e.target.value).diff(moment(prev), "days");
                                      const newValue = parseInt(input.value, 10) + diff;
                                      if (newValue <= 0) {
                                        e.preventDefault();
                                        return;
                                      }
                                      form.change(`${fieldName}.intervalDays`, newValue);
                                      // adjust future events
                                      fields.value.slice(index + 1).forEach((f, i) => {
                                        form.change(
                                          `templateSteps[${i + index + 1}].intervalDays`,
                                          Math.max(1, f.intervalDays - diff),
                                        );
                                      });
                                    }}
                                  />
                                </>
                              )}
                              {meta.error && meta.touched && <small>{meta.error}</small>}
                            </>
                          )}
                        </Field>
                      )}
                      <UncontrolledDropdown>
                        <DropdownToggle caret>{S.TPL_ACTIONS_BUTTON}</DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem disabled={index === 0} onClick={() => deleteStep(fields, index)}>
                            {S.TPL_DELETE_BUTTON}
                          </DropdownItem>
                          <DropdownItem divider />
                          {fields.map((_f, fi) => (
                            <DropdownItem
                              key={fi}
                              value={fi}
                              disabled={index == fi || !isStepEditable(field, fi)}
                              onClick={e => handleMoveStep(fields, index, e)}>
                              Move to position {fi + 1}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </Col>
                </Row>
                {field.input.value[index]?.kind === "email" ? (
                  <>
                    <Row>
                      <Col>
                        <AsyncTypeaheadField
                          name={`${fieldName}.to`}
                          label={S.TPL_MAIL_TO}
                          fetch={mockFetch(toEmailList)}
                          defaultOptions={toEmailList}
                          minLength={0}
                          defaultSelected={getSelectedEmails(toEmailList, field.input.value[index]?.to)}
                          multiple
                        />
                        <OnChange name={`${fieldName}.to`}>
                          {value => {
                            if (value && value.length > 0 && value[0].value) {
                              const newVals = value.map(({ value }) => value);
                              form.change(`${fieldName}.to`, newVals);
                            }
                          }}
                        </OnChange>
                      </Col>
                      <Col>
                        <AsyncTypeaheadField
                          multiple
                          name={`${fieldName}.replyTo`}
                          label={S.TPL_MAIL_REPLY_TO}
                          fetch={mockFetch(toEmailList)}
                          defaultOptions={toEmailList}
                          minLength={0}
                          defaultSelected={getSelectedEmails(toEmailList, field.input.value[index]?.replyTo)}
                        />
                        <OnChange name={`${fieldName}.replyTo`}>
                          {value => {
                            if (value && value.length > 0 && value[0].value) {
                              const newVals = value.map(({ value }) => value);
                              form.change(`${fieldName}.replyTo`, newVals);
                            }
                          }}
                        </OnChange>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <AsyncTypeaheadField
                          name={`${fieldName}.cc`}
                          label={S.TPL_MAIL_CC}
                          fetch={mockFetch(toEmailList)}
                          defaultOptions={toEmailList}
                          minLength={0}
                          defaultSelected={getSelectedEmails(toEmailList, field.input.value[index]?.cc)}
                          multiple
                        />
                        <OnChange name={`${fieldName}.cc`}>
                          {value => {
                            if (value && value.length > 0 && value[0].value) {
                              const newVals = value.map(({ value }) => value);
                              form.change(`${fieldName}.cc`, newVals);
                            }
                          }}
                        </OnChange>
                      </Col>
                      <Col>
                        <AsyncTypeaheadField
                          name={`${fieldName}.bcc`}
                          label={S.TPL_MAIL_BCC}
                          fetch={mockFetch(toEmailList)}
                          defaultOptions={toEmailList}
                          minLength={0}
                          defaultSelected={getSelectedEmails(toEmailList, field.input.value[index]?.bcc)}
                          multiple
                        />
                        <OnChange name={`${fieldName}.bcc`}>
                          {value => {
                            if (value && value.length > 0 && value[0].value) {
                              const newVals = value.map(({ value }) => value);
                              form.change(`${fieldName}.bcc`, newVals);
                            }
                          }}
                        </OnChange>
                      </Col>
                    </Row>
                    <Row>
                      <div className="editor-dropdown">
                        <label htmlFor={`${fieldName}.subject`}>{S.TPL_MAIL_SUBJECT}</label>
                        <VariableSelectDropdown
                          enabled={mailSubjectEditorActive}
                          label={S.TPL_SMS_ADD_FIELD}
                          columns={variableOptions}
                          onClick={value => {
                            insertValueIntoEditor(
                              `${fieldName}.subject`,
                              value.currentTarget.getAttribute("aria-value"),
                            );
                          }}
                        />
                      </div>
                      <Field type="hidden" name={`${fieldName}.subject`} id={`${fieldName}.subject`}>
                        {({ input: { value, ...input }, meta }) => (
                          <InputReactQuill
                            defaultValue={value}
                            ref={el => {
                              editorRef.current[`${fieldName}.subject`] = el;
                            }}
                            className={classNames({ hasError: meta.error })}
                            onFocus={() => setMailSubjectEditorActive(true)}
                            onBlur={() => setMailSubjectEditorActive(false)}
                            onChange={(newValue, delta, source) => {
                              if (source === "user") {
                                const cleanedValue = newValue.replace(/<[^>]*>/g, "").replace(/&nbsp;/g, " ");
                                input.onChange(cleanedValue);
                              }
                            }}
                          />
                        )}
                      </Field>
                    </Row>
                    <Row>
                      <Col>
                        <div className="editor-dropdown">
                          <label>{S.TPL_MAIL_CONTENT}</label>
                          <VariableSelectDropdown
                            enabled={mailBodyEditorActive}
                            label={S.TPL_SMS_ADD_FIELD}
                            columns={variableOptions}
                            onClick={value => {
                              insertValueIntoEditor(
                                `${fieldName}.content`,
                                value.currentTarget.getAttribute("aria-value"),
                              );
                            }}
                          />
                          <VariableSelectDropdown
                            enabled={mailBodyEditorActive}
                            label={S.TPL_SMS_ADD_CTA}
                            columns={CTA_LINK_OPTIONS}
                            onClick={value => {
                              insertValueIntoEditor(
                                `${fieldName}.content`,
                                value.currentTarget.getAttribute("aria-value"),
                                true,
                              );
                            }}
                          />
                        </div>

                        <Field type="hidden" name={`${fieldName}.content`} id="content">
                          {({ input: { value, ...input }, meta }) => (
                            <ReactQuill
                              theme="snow"
                              defaultValue={value}
                              ref={el => {
                                editorRef.current[`${fieldName}.content`] = el;
                              }}
                              className={classNames({ hasError: meta.error })}
                              onFocus={() => setMailBodyEditorActive(true)}
                              onBlur={() => setMailBodyEditorActive(false)}
                              onChange={(newValue, delta, source) => {
                                if (source === "user") {
                                  input.onChange(newValue);
                                }
                              }}
                            />
                          )}
                        </Field>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col>
                        <AsyncTypeaheadField
                          name={`${fieldName}.to`}
                          label={S.TPL_PHONE_TO}
                          fetch={mockFetchForPhone(toPhoneList)}
                          defaultOptions={toPhoneList}
                          minLength={0}
                          defaultSelected={getSelectedPhones(toPhoneList, field.input.value[index]?.to)}
                        />
                        <OnChange name={`${fieldName}.to`}>
                          {value => {
                            if (value && value.length > 0 && value[0].value) {
                              const newVals = value.map(({ value }) => value);
                              form.change(`${fieldName}.to`, newVals);
                            }
                          }}
                        </OnChange>
                      </Col>
                    </Row>
                    <Row>
                      <div className="editor-dropdown">
                        <label>{S.TPL_SMS_TITLE}</label>
                        <VariableSelectDropdown
                          enabled={smsEditorActive}
                          label={S.TPL_SMS_ADD_FIELD}
                          columns={variableOptions}
                          onClick={value => {
                            insertValueIntoEditor(
                              `${fieldName}.content`,
                              value.currentTarget.getAttribute("aria-value"),
                            );
                          }}
                        />
                      </div>

                      <Field type="hidden" name={`${fieldName}.content`} id="content">
                        {({ input: { value, ...input }, meta }) => (
                          <ReactQuill
                            theme="snow"
                            defaultValue={addNewlines(value)}
                            modules={{
                              toolbar: false,
                            }}
                            ref={el => {
                              editorRef.current[`${fieldName}.content`] = el;
                            }}
                            className={classNames({ hasError: meta.error })}
                            onFocus={() => setSmsEditorActive(true)}
                            onBlur={() => setSmsEditorActive(false)}
                            onChange={(newValue, delta, source) => {
                              if (source === "user") {
                                newValue = stripTagsKeepNewlines(newValue);
                                input.onChange(newValue);
                              }
                            }}
                          />
                        )}
                      </Field>
                    </Row>
                  </>
                )}
              </div>
            );
          })
        }
      </FieldArray>
      <Row>
        <Col className={classNames("buttons", { "empty-state": isEmpty })}>
          {isEmpty && <p>{S.TPL_EMPTY_STATE}</p>}
          <Button variant="contained" component="span" className="button" onClick={onAddClicked} size="large">
            <Icon>add</Icon>
            {S.TPL_ADD_STEP}
          </Button>
        </Col>
      </Row>
    </>
  );
};
