import { get } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";

import { fetchProduct } from "src/store/reducers/productsV2";
import NumberFormatField from "../../../../../components/Form/NumberFormatField/NumberFormatField";
import SingleSelectField from "../../../../../components/Form/SingleSelectField/SingleSelectField";
import * as S from "../../../../../constants/StringConstants";
import { IRootState } from "../../../../../store/reducers";
import { addDefaultOption, addDefaultOptionFiltering } from "../../../../../util/utils.defaultValues";

export interface IAnalyzerColumnProps extends StateProps, DispatchProps {
  name: string;
  value: any;
  productTypeID: number;
  index: number;
  remove: (index: number) => any;
}

class AnalyzerColumn extends React.Component<IAnalyzerColumnProps> {
  get productID() {
    const {
      productTypeID,
      value: { carrierTypeID, designTypeID },
    } = this.props;
    const product = Object.values(this.props.products.map).find(row => {
      return (
        row.productTypeID === productTypeID && row.carrierTypeID === carrierTypeID && row.designTypeID === designTypeID
      );
    });
    return product ? product.id : null;
  }

  async componentDidMount() {
    if (this.productID) {
      await this.props.fetchProduct(this.productID);
    }
  }

  render() {
    const { name, value } = this.props;

    const renewabilityTypeID = get(value, "analyzer.renewabilityTypeID", "");
    const bpTypeID = get(value, "analyzer.bpTypeID", "");
    const epTypeID = get(value, "analyzer.epTypeID", "");
    const ownOccTypeID = get(value, "analyzer.ownOccTypeID", "");
    const residualTypeID = get(value, "analyzer.residualTypeID", "");
    const minimumResidualBenefitPayableTypeID = get(value, "analyzer.minimumResidualBenefitPayableTypeID");
    const futureInsurabilityOptionsTypeID = get(value, "analyzer.futureInsurabilityOptionsTypeID");
    const airTypeID = get(value, "analyzer.airTypeID", "");
    const colaTypeID = get(value, "analyzer.colaTypeID", "");
    const mentalNervousLimitationTypeID = get(value, "analyzer.mentalNervousLimitationTypeID");
    const catastrophicBenefitRiderTypeID = get(value, "analyzer.catastrophicBenefitRiderTypeID");

    const product = this.props.products.map[this.productID];

    if (!product) {
      return null;
    }

    const carrierOptions = addDefaultOption("", this.props.allOptions.carrierTypeList);
    const designOptions = addDefaultOption("", this.props.allOptions.designTypeList);
    const occClassOptions = addDefaultOption("", this.props.allOptions.occupationClassTypeList);

    const renewabilityOptions = addDefaultOptionFiltering(
      this.props.allOptions.renewabilityTypeList,
      product.renewabilityTypeIDs,
    );
    const bpOptions = addDefaultOptionFiltering(this.props.allOptions.bpTypeList, product.bpTypeIDs);
    const epOptions = addDefaultOptionFiltering(this.props.allOptions.epTypeList, product.epTypeIDs);
    const ownOccOptions = addDefaultOptionFiltering(this.props.allOptions.ownOccTypeList, product.ownOccTypeIDs);
    const residualOptions = addDefaultOptionFiltering(this.props.allOptions.residualTypeList, product.residualTypeIDs);
    const minimumResidualBenefitPayableOptions = addDefaultOptionFiltering(
      this.props.allOptions.minimumResidualBenefitPayableTypeList,
      product.minimumResidualBenefitPayableTypeIDs,
    );
    const futureInsurabilityOptions = addDefaultOptionFiltering(
      this.props.allOptions.futureInsurabilityOptionsTypeList,
      product.futureInsurabilityOptionsTypeIDs,
    );
    const airOptions = addDefaultOptionFiltering(this.props.allOptions.airTypeList, product.airTypeIDs);
    const colaOptions = addDefaultOptionFiltering(this.props.allOptions.colaTypeList, product.colaTypeIDs);
    const mentalNervousLimitationOptions = addDefaultOptionFiltering(
      this.props.allOptions.mentalNervousLimitationTypeList,
      product.mentalNervousLimitationTypeIDs,
    );
    const catastrophicBenefitRidersOptions = addDefaultOptionFiltering(
      this.props.allOptions.catastrophicBenefitRiderTypeList,
      product.catastrophicBenefitRiderTypeIDs,
    );
    const uniqueProvisionsOptions = addDefaultOptionFiltering(
      this.props.allOptions.uniqueProvisionsTypeList,
      product.uniqueProvisionsTypeIDs,
    );

    return (
      <Col key={`${name}`} sm="4">
        <Container className="classifications__condition-wrap">
          <Row>
            <Col>
              <SingleSelectField
                key="carrier-field"
                name={`${name}.carrierTypeID`}
                label={S.PBC_CARRIER_LABEL}
                disabled={true}
                options={carrierOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                key={"occ-field"}
                name={`${name}.occupationClassTypeID`}
                label={S.PBC_OCC_LABEL}
                disabled={true}
                options={occClassOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                key={"design-field"}
                name={`${name}.designTypeID`}
                label={S.PBC_PRODUCT_LABEL}
                disabled={true}
                options={designOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <NumberFormatField
                key={"base-benefit-amount-field"}
                name={`${name}.analyzer.baseBenefitAmount`}
                label={S.ANL_BASE_BENEFIT_LABEL}
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={2}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <NumberFormatField
                key={"ssib-field"}
                name={`${name}.analyzer.ssib`}
                label={S.ANL_SSIB_LABEL}
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={2}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <NumberFormatField
                key={"total-benefit-field"}
                name={`${name}.analyzer.totalBenefitAmount`}
                disabled={true}
                label={S.ANL_TOTAL_BENEFIT_LABEL}
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={2}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                key={"renewability-field"}
                name={`${name}.analyzer.renewabilityTypeID`}
                label={S.ANL_RENEWABILITY_LABEL}
                options={renewabilityOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                disabled={renewabilityTypeID == ""}
                key={"bp-field"}
                name={`${name}.analyzer.bpTypeID`}
                label={S.ANL_BP_LABEL}
                options={bpOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                disabled={bpTypeID == ""}
                key={"ep-field"}
                name={`${name}.analyzer.epTypeID`}
                label={S.ANL_EP_LABEL}
                options={epOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                disabled={epTypeID == ""}
                key={"own-occ-field"}
                name={`${name}.analyzer.ownOccTypeID`}
                label={S.ANL_OWN_OCC_LABEL}
                options={ownOccOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                disabled={ownOccTypeID == ""}
                key={"residual-field"}
                name={`${name}.analyzer.residualTypeID`}
                label={S.ANL_RESIDUAL_LABEL}
                options={residualOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                disabled={residualTypeID == "" || minimumResidualBenefitPayableOptions.length <= 1}
                key={"minimum-residual-field"}
                name={`${name}.analyzer.minimumResidualBenefitPayableTypeID`}
                label={S.ANL_MINIMUM_RESIDUAL_LABEL}
                options={minimumResidualBenefitPayableOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                disabled={minimumResidualBenefitPayableTypeID == "" || futureInsurabilityOptions.length <= 1}
                key={"future-insurability-field"}
                name={`${name}.analyzer.futureInsurabilityOptionsTypeID`}
                label={S.ANL_FUTURE_INSURABILITY_LABEL}
                options={futureInsurabilityOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                disabled={futureInsurabilityOptionsTypeID == "" || airOptions.length <= 1}
                key={"air-field"}
                name={`${name}.analyzer.airTypeID`}
                label={S.ANL_AIR_LABEL}
                options={airOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                disabled={airTypeID == "" || colaOptions.length <= 1}
                key={"cola-field"}
                name={`${name}.analyzer.colaTypeID`}
                label={S.ANL_COLA_LABEL}
                options={colaOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                disabled={colaTypeID == "" || mentalNervousLimitationOptions.length <= 1}
                key={"mental-nervous-limitation-field"}
                name={`${name}.analyzer.mentalNervousLimitationTypeID`}
                label={S.ANL_MENTAL_NERVOUS_LABEL}
                options={mentalNervousLimitationOptions}
              />
            </Col>
          </Row>
          <Row className="bootstrap-col-pad-override">
            <Col>
              <SingleSelectField
                disabled={mentalNervousLimitationTypeID == "" || catastrophicBenefitRidersOptions.length <= 1}
                key={"catastrophic-benefit-select-field"}
                name={`${name}.analyzer.catastrophicBenefitRiderTypeID`}
                label={S.ANL_CATASTROPHIC_LABEL}
                options={catastrophicBenefitRidersOptions}
              />
            </Col>
            <Col>
              <NumberFormatField
                key={"catastrophic-amount-field"}
                name={`${name}.analyzer.catastrophicAmount`}
                label={S.ANL_CATASTROPHIC_AMOUNT_LABEL}
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={2}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                disabled={catastrophicBenefitRiderTypeID == "" || uniqueProvisionsOptions.length <= 1}
                key={"unique-provisions-field"}
                name={`${name}.analyzer.uniqueProvisionsTypeID`}
                label={S.ANL_UNIQUE_PROVISION_LABEL}
                options={uniqueProvisionsOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <NumberFormatField
                key={"total-value-amount-field"}
                name={`${name}.analyzer.potentialBenefitPayout`}
                label={S.ANL_POTENTIAL_BENEFIT_LABEL}
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={2}
                disabled={true}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <NumberFormatField
                key={"ap-amount-field"}
                name={`${name}.analyzer.annualPremium`}
                label={S.ANL_AP_LABEL}
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={2}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <NumberFormatField
                key={"total-monthly-amount-field"}
                name={`${name}.analyzer.monthlyPremium`}
                label={S.ANL_MONTHLY_LABEL}
                thousandSeparator={true}
                prefix={"$"}
                disabled={true}
                decimalScale={2}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <NumberFormatField
                key={"cost-per-100-field"}
                name={`${name}.analyzer.costPer100Benefit`}
                label={S.ANL_COST_PER_100_LABEL}
                thousandSeparator={true}
                prefix={"$"}
                disabled={true}
                decimalScale={2}
              />
            </Col>
          </Row>
        </Container>
      </Col>
    );
  }
}

const mapStateToProps = function (state: IRootState) {
  return {
    allOptions: state.productBuilder,
    products: state.productsV2,
  };
};

const mapDispatchToProps = {
  fetchProduct,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AnalyzerColumn);
