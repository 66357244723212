import { set } from "lodash";
import moment from "moment";
import * as S from "src/constants/StringConstants";
import { IIOCampaignCreationData } from "src/store/models/campaign.model";
import { CampaignSteps } from "./steps";

export const campaignBuilderValidations = (
  values: IIOCampaignCreationData,
  activeStep: number,
  activeCampaign = false,
) => {
  const errors = {};

  if (activeStep === CampaignSteps.JOURNEY) {
    const isTemplateValueEmpty = !values.templateSteps || values.templateSteps.length === 0;

    if (isTemplateValueEmpty) {
      set(errors, "templateSteps", S.CS_ERROR_STEP_CANNOT_BE_NULL);
    } else {
      values.templateSteps.forEach((step, index) => {
        if (step.to.length === 0) {
          set(errors, `templateSteps[${index}].to`, S.CS_ERROR_STEP_TO);
        }
        // check correct message interval for 1..
        if (index > 0 && !(step.intervalDays >= 1)) {
          set(errors, `templateSteps[${index}].intervalDays`, S.CS_ERROR_STEP_INTERVAL);
        }
      });
    }

    const isTimeNull = values.options?.time === null;
    if (isTimeNull) {
      set(errors, "options.time", S.CS_ERROR_TIME_NULL);
    }

    const isInvalidFirstDeliveryDate =
      values.options?.firstDeliveryDate === null || isNaN(new Date(values.options?.firstDeliveryDate).getTime());
    if (isInvalidFirstDeliveryDate) {
      set(errors, "options.firstDeliveryDate", S.CS_ERROR_INVALID_DATE);
    }

    const isFirstDeliveryDateInPast =
      moment(values.options?.firstDeliveryDate).startOf("day") < moment().startOf("day");

    if (!activeCampaign && isFirstDeliveryDateInPast) {
      set(errors, "options.firstDeliveryDate", S.CS_ERROR_FIRST_DELIVERY_DATE_IN_PAST);
    }
  }

  return errors;
};
