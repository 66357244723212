import { IIOCampaignCreationData, IIOCampaignRequestPayload, IOPayloadStep } from "src/store/models/campaign.model";
import { IIOTemplateStep } from "src/store/models/template.model";
import { stripTags, surroundWithBracketsIfNeeded } from "./utils";

const emptyStep = {
  stepOrder: 10000,
  kind: "",
  to: "",
  from: "",
  cc: "",
  bcc: "",
  replyTo: "",
  subject: "",
  content: "",
};

export function generateCampaignPayload(data: IIOCampaignCreationData, userId: number): IIOCampaignRequestPayload {
  const { saveAsDraft, campaignId } = data;
  const steps = data.templateSteps.map((step: IIOTemplateStep, index): IOPayloadStep => {
    const ret: IOPayloadStep = {
      stepId: step.stepId,
      kind: step.kind,
      content: step.content,
      stepOrder: index,
      actionDatetime: step.actionDatetime,
      intervalDays: step.intervalDays,
    };
    if (step.kind === "email") {
      ret.subject = stripTags(step.subject);
      ret.to = surroundWithBracketsIfNeeded(step.to);
      ret.from = surroundWithBracketsIfNeeded(step.from);
      ret.cc = surroundWithBracketsIfNeeded(step.cc);
      ret.bcc = surroundWithBracketsIfNeeded(step.bcc);
      ret.replyTo = surroundWithBracketsIfNeeded(step.replyTo);
      ret.content = step.content;
    } else {
      ret.subject = "";
      ret.to = surroundWithBracketsIfNeeded(step.to);
      ret.from = "";
      ret.cc = "";
      ret.bcc = "";
      ret.replyTo = "";
      // cleanup html from SMS content
      ret.content = stripTags(ret.content);
    }
    return ret;
  });

  const deletedSteps = campaignId
    ? data.deletedSteps
        .map(stepId => ({ stepId: stepId, stepRemovedByUser: true, ...emptyStep }))
        .filter(({ stepId }) => !!stepId)
    : [];

  return {
    ...(campaignId ? { campaignId } : {}),
    campaignName: data.fileName,
    timeToSend: `${data.options.time}`,
    rowData: JSON.stringify(data.rowData),
    userId,
    carrierId: data.options.carrierId,
    carrierType: "string",
    saveAsDraft: saveAsDraft,
    emailFooter: data.options.emailFooter,
    deliveryDate: data.options.firstDeliveryDate,
    deliveryTime: data.options.time,
    rawFileData: JSON.stringify({ columnNames: data.columnNames, rows: data.rows }),
    fieldMapping: JSON.stringify(data.mapTo),
    steps: [...steps, ...deletedSteps],
  };
}
