import { get } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Button } from "reactstrap";
import SearchField from "src/components/Table/SearchField";
import StatusFilter from "src/components/Table/StatusFilter";
import { IUser } from "src/store/models/user.model";
import { IRootState } from "src/store/reducers";
import { fetchUsersList, removeUser } from "src/store/reducers/users";
import DisTable from "../../components/Table/Table";
import * as S from "../../constants/StringConstants";

interface MatchParams {
  userId?: string;
}

interface UsersProps extends RouteComponentProps<MatchParams> {}

const Users: React.FC<UsersProps> = ({ history }) => {
  const [total, setTotal] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  const dispatch = useDispatch();
  const loggedInUserId = useSelector((state: IRootState) => state.auth.user.userID);

  const columns = [
    {
      key: "name",
      title: S.U_COL_NAME,
      style: { minWidth: "5rem" },
      render: (user: any) => `${user.firstName} ${user.lastName}`,
    },
    {
      key: "email",
      title: S.U_COL_EMAIL,
      style: { minWidth: "5rem" },
    },
    {
      key: "role",
      title: S.U_COL_ROLE,
      style: { minWidth: "5rem" },
    },
    {
      key: "branch",
      title: S.U_COL_BRANCH,
      style: { minWidth: "5rem" },
    },
    {
      key: "rbm",
      title: S.U_COL_RBM,
      style: { minWidth: "5rem" },
    },
    {
      key: "actions",
      title: S.U_COL_DELETE,
      style: { minWidth: "8rem" },
      render: user => {
        if (user.userID === loggedInUserId) {
          return null;
        }

        return user.isDeleted ? (
          <span>{S.U_DELETED}</span>
        ) : (
          <Button
            onClick={async e => {
              e.stopPropagation();
              if (confirm(S.U_CONFIRM_DELETE)) {
                await dispatch(removeUser(user.userID));
                setRefreshKey(prevKey => prevKey + 1);
              }
            }}>
            {S.U_COL_DELETE}
          </Button>
        );
      },
    },
  ];

  const userStatusList = [
    { key: "Active", value: 1 },
    { key: "Inactive", value: 0 },
  ];

  const handleUpdate = async ({ page, rows, sort, query }) => {
    try {
      const result: any = await dispatch(fetchUsersList({ page, rows, sort, query }));
      const fetchedUsers = get(result, "value.data.items", []);
      const totalRows = get(result, "value.data.totalRows", 0);

      setTotal(totalRows);

      return {
        total: totalRows,
        data: fetchedUsers,
      };
    } catch (error) {}
  };

  return (
    <div className="table-view__content">
      <h1 className="heading1 grey--light">{S.U_PAGE_TITLE}</h1>
      <DisTable<IUser>
        key={refreshKey}
        columns={columns}
        onRowClick={(user: any) => {
          if (user.id !== loggedInUserId) {
            history.push(`/users/${user.id}`, { user });
          }
        }}
        initialState={{ rows: 25, total }}
        onUpdate={handleUpdate}>
        <StatusFilter statusList={userStatusList} useIsDeletedFilter={true} />
        <SearchField />
      </DisTable>
    </div>
  );
};

export default Users;
